import * as React from 'react'
import { useState, useEffect } from 'react'
import i18n from '../i18n'
import { 
  ToggleButton,
  ToggleButtonGroup,
  } from '@mui/material'

function LangSelector() {

  const [lang, setLang] = useState(null)

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
      localStorage.setItem("lang", lang)
    }
  }, [lang])

  useEffect(() => {
    const _lang = localStorage.getItem("lang")
    if (_lang) {
     setLang(_lang)
    }
  }, [])

  function handleChange(event, selectedLang) {
    setLang(selectedLang)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      sx={{ mt: 4, mr: 1 }}
      value={lang}
      onChange={handleChange}
      exclusive={true} >
      <ToggleButton size="small" value="ja">ja</ToggleButton>
      <ToggleButton size="small" value="en">en</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default LangSelector;
