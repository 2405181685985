import { constants } from './Constants'
import { graphqlOperation, API, Storage } from 'aws-amplify'
import { getFirmwareV2, getFirmwareFromZipV2 } from '../graphql/queries'


async function getHasFirmStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.FIRMWARE_DIR + "/" + constants.STATUS_JSON, 
      args)
    return true
  } catch (err) {
    return false
  }
}


async function loadFirmInfo(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + '/' + constants.FIRMWARE_DIR + '/' + constants.STATUS_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    return JSON.parse(data)
  } catch (err) {
    console.log('error loadFirmInfo', err)
  }
}


async function getFirmStatus(projectId) {
  console.log(projectId)
  try {
    const data = await loadFirmInfo(projectId)
    /*
      {
        "created": "2021-10-21 21:55:04.233450",
        "unrouted": "2021-10-21 21:55:05.979837",
        "dsn": "2021-10-21 21:55:08.326334",
        "session": "2021-10-21 21:58:01.885247",
        "routed": "2021-10-21 21:58:12.025637",
        "printfiles": "2021-10-21 21:58:15.861096",
        "completed"
      }
    */
    const sorted = []
    for (var key in data) {
      sorted.push([key, data[key]])
    }
    sorted.sort(function(a, b) {
        return b[1] - a[1]
    })
    return sorted[0][0]
  } catch (err) {
    console.log('error getFirmStatus', err)
  }
}


async function checkFirmUpdated(projectId) {
  let kbdata_last_modified = new Date(2000, 1, 2)
  let firm_last_modified = new Date(2000, 1, 1)
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.KBDATA_JSON, 
      args)
    kbdata_last_modified = result.lastModified
  } catch (err) {
    console.log('error kbdata_last_modified') 
  }

  try {
    const result = await Storage.getProperties(
      projectId + '/' + constants.FIRMWARE_DIR + '/' + constants.STATUS_JSON, 
      args)
    firm_last_modified = result.lastModified
  } catch (err) {
    console.log('error firm_last_modified') 
  }
  console.log('kbdata_last_modified ', kbdata_last_modified)
  console.log('firm_last_modified ', firm_last_modified)
  return kbdata_last_modified < firm_last_modified
}


async function generateFirm(projectId) {
  try {
    await API.graphql(
      graphqlOperation(
        getFirmwareV2, {
          dir: projectId
        }
      )
    )
  } catch (err) {
    console.log('error generateFirm') 
  }
}


async function generateFirmFromZip(projectId) {
  try {
    await API.graphql(
      graphqlOperation(
        getFirmwareFromZipV2, {
          dir: projectId
        }
      )
    )
  } catch (err) {
    console.log('error generateFirmFromZip') 
  }
}


async function getFirm(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + '/' + constants.FIRMWARE_DIR + '/' + constants.FIRM_FILE,
      args
    )
    return data
  } catch (err) {
    console.log('error getFirm', err)
  }
}


export { getHasFirmStatus,
  getFirmStatus,
  checkFirmUpdated,
  generateFirm,
  getFirm,
  generateFirmFromZip
}