const constants = {
  INITIAL_KBDATA_URL_TYPE1: 'kbdata_4x4.json',
  INITIAL_KBDATA_URL_TYPE2: 'kbdata_5x3.json',
  INITIAL_KBDATA_URL_TYPE7: 'kbdata_5x5.json',
  INITIAL_KBDATA_URL_TYPE8: 'kbdata_5x5_w.json',
  KBDATA_JSON: 'kbdata.json',
  KEYBOARD_SVG: 'keyboard.svg',
  BOARD_DIR: 'board',
  FIRMWARE_DIR: 'firmware',
  ENCLOSURE_DIR: 'enclosure',
  BOARD_FILE: 'moved.kicad_pcb',
  GCODE_FILE: 'gcode.zip',
  GERBER_FILE: 'gerber.zip',
  FIRM_FILE: 'firmware.uf2',
  FIRM_SOURCE_FILE: 'firmware.zip',
  PRINT_DIR: 'print',
  GCODE_DIR: 'gcode',
  STATUS_JSON: 'status.json',
  THREED_DIR: 'threed',
  KEYCAPS_STL: 'keycaps.stl',
  KEYSWITCHS_STL: 'keyswitchs.stl',
  AXIS_STL: 'axis.stl',
  ENCLOSURE_STL: 'enclosure.stl',
  MODULE_STL: 'module.stl',
  PINHEADER_PIN_STL: 'pinheader_pin.stl',
  PINHEADER_PLASTIC_STL: 'pinheader_plastic.stl',
  USB_STL: 'usb.stl',
  PCB_STL: 'pcb.stl',
  TOP_SVG: 'top.svg',
  BOTTOM_SVG: 'bottom.svg',
  TOP_PDF: 'top.pdf',
  BOTTOM_PDF: 'bottom.pdf',
  OPTIONS_JSON: 'options.json',
  ORDER_JSON: 'order.json',
  LITE_PATH: 'lite',
  CUSTOM_PREFIX: {
    level: 'private',
    customPrefix: { private: '' }
  },
  CUSTOM_ARG_PUBLIC: {
    level: 'public',
    customPrefix: { public: 'admin/' }
  },
  PIPICO_WIDTH: 21,
  PIPICO_HEIGHT: 51,
  BOARD_MARGIN_S: 1,
  BOARD_MARGIN_L: 2,
  MOD_KEY_MARGIN: 1,
  KEYCAP_MARGIN: 1
}

export { constants }

