import { useEffect, useState, useRef } from 'react'
import React from 'react';
import Sourface from './Sourface'
import DownloadIcon from '@mui/icons-material/Download'
import { Button, 
  Box,
  CardContent,
  Typography,
  Stack,
  Paper,
  } from '@mui/material'
import { getImageUrls, 
  getImageStatus,
  getHasImageStatus } from '../models/ImageManager'
// import { loadProject } from '../models/ProjectManager'
import CircularProgress from '@mui/material/CircularProgress'
// import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Images(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [imageUrls, setImageUrls] = useState({
    svg: {
      top: '', 
      bottom: ''
    },
    pdf: {
      top: '', 
      bottom: ''
    }
  })
  const [imageStatus, setImageStatus] = useState(null)
  const [imageFailed, setImageFailed] = useState(false)
  const [imageWatching, setImageWatching] = useState(false)
  const [imageGenerated, setImageGenerated] = useState(false)
  /*
  const [boardSize, setBoardSize] = useState({
    width: 0, 
    height: 0,
  })
  */

  const { t } = useTranslation()

  /*
  useEffect(() => {
    console.log(projectId)
    async function doGet() {
      const json_data = await loadProject(projectId)
      console.log(json_data)
      setBoardSize({width: json_data.board.w, height: json_data.board.h})
    }
    doGet()
  }, [projectId])
  */

  /*
  const rows = [
    [
      {label: 'h', value: boardSize.width},
      {label: 't', value: 1.6}
    ],
    [
      {label: 'w', value: boardSize.height},
      {label: 'u', value: 2.0}
    ],
    [
      {label: 'd', value: 2.0},
      {label: 's', value: 1.5}
    ]
  ]
  */
  
  let imageTimer = useRef()

  function getStatusWithProgress() {
    const status = [
      "started",
      "completed"
    ]
    if (0 <= status.indexOf(imageStatus)) {
      return (status.indexOf(imageStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  useEffect(() => {
    getHasImageStatus(projectId).then(data => {
      console.log('getHasImageStatus', data)
      if (data === true) {
        setImageWatching(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (imageWatching === true) {
      function intervalImageStatus() {
        getImageStatus(projectId).then(data => {
          console.log('getImageStatus', data)
          setImageStatus(data)
        })
      }
      imageTimer.current = setInterval(intervalImageStatus, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWatching, projectId])
  

  useEffect(() => {
    if (imageStatus === "completed" || imageStatus === "error") {
      setImageWatching(false)
      clearInterval(imageTimer.current)
      if (imageStatus === "completed") {
        setImageGenerated(true)
      } else {
        setImageFailed(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageStatus])
  
  
  useEffect(() => {
    console.log("imageGenerated " + imageGenerated)
    getImageUrls(projectId).then(data => {
      setImageUrls(data)
      props.setDone(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageGenerated, projectId, props])
  
 
  return (
    <Box>
      { imageGenerated ?
          <>
            <Paper
              sx={{
                bgcolor: '#334',
                mt: 2,
                mb: 2
              }}
              variant="outlined">
              { imageStatus && imageUrls.svg.top !== '' ?
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}>
                      <Typography>{t('Top')}</Typography>
                      <Button 
                        endIcon={<DownloadIcon />}
                        href={imageUrls.pdf.top}>
                        PDF
                      </Button>
                    </Stack>
                    <Sourface src={imageUrls.svg.top} />
                  </CardContent>
                  :
                  ''
              }
              { imageStatus && imageUrls.svg.bottom !== '' ?
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}>
                      <Typography>{t('Bottom')}</Typography>
                      <Button 
                        endIcon={<DownloadIcon />}
                        href={imageUrls.pdf.bottom}>
                        PDF
                      </Button>
                    </Stack>
                    <Sourface src={imageUrls.svg.bottom} />
                  </CardContent>
                :
                  ''
              }
            </Paper>
            {/*
              <Paper
                sx={{
                  bgcolor: '#334',
                  mt: 2,
                  mb: 2
                }}
                variant="outlined">
                <ReactSVG src="/size.svg" />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">&nbsp;</TableCell>
                        <TableCell align="right">&nbsp;(mm)</TableCell>
                        <TableCell align="right">&nbsp;</TableCell>
                        <TableCell align="right">&nbsp;(mm)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="right">{row[0].label}</TableCell>
                          <TableCell align="right">{row[0].value}</TableCell>
                          <TableCell align="right">{row[1].label}</TableCell>
                          <TableCell align="right">{row[1].value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            */}
          </>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !imageFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { imageStatus && !imageFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {imageStatus}</Typography>
            :
              ""
            }
            <Typography>Images</Typography>
            { imageFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </Box>
  )
}
