import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { 
  Container,
  Box,
  Toolbar,
  Button,
  Link,
  Typography
} from '@mui/material'
import { useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { graphqlOperation, API } from 'aws-amplify'
import { getDownloadUrlV2 } from '../graphql/queries'
import DownloadIcon from '@mui/icons-material/Download'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

// http://localhost:3000/order/5196c258-7113-43dd-a7d1-4afd0ae27897

function Order() {

  let { projectId } = useParams()
  let navigate = useNavigate()

  const [downloadUrl, setDownloadUrl] = useState(null)

  useEffect(() => {
    async function getUrl() {
      try {
        const value = await API.graphql(
          graphqlOperation(getDownloadUrlV2, { dir: projectId })
        )
        console.log(value.data.getDownloadUrlV2)
        setDownloadUrl(value.data.getDownloadUrlV2)
      } catch (err) {
        console.log('error getDownloadUrlV2')
      }
    }
    getUrl()
  }, [projectId, navigate])

  function downloadHandler(event) {
    console.log('downloadHandler: ' + downloadUrl)
    window.open(downloadUrl, '_blank');
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Box>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mt: 7,
            mb: 5 }}>
            { downloadUrl ?
                <Button onClick={downloadHandler} endIcon={<DownloadIcon />}>
                  Download
                </Button>
              :
                <Typography>存在しません</Typography>
            }
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Order;
