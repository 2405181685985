import { Storage } from 'aws-amplify'
import { constants } from './Constants'
import { v4 as uuidv4 } from 'uuid'

async function createNewProject(type) {

  const projectId = uuidv4()
  let kbdata_json = undefined
  // let keyboard_svg = undefined
  let initial_kbdata_url = undefined

  console.log("createNewProject: " + type)

  if(type === 1) {
    // not /xxx/kbdata_x.json but /kbdata_x.json
    initial_kbdata_url = "/" + constants.INITIAL_KBDATA_URL_TYPE1
  } else if(type === 2) {
    // not /xxx/kbdata_x.json but /kbdata_x.json
    initial_kbdata_url = "/" + constants.INITIAL_KBDATA_URL_TYPE2
  } else if(type === 7) {
    initial_kbdata_url = constants.INITIAL_KBDATA_URL_TYPE7
  } else if(type === 8) {
    initial_kbdata_url = constants.INITIAL_KBDATA_URL_TYPE8
  }

  try {
    kbdata_json = await fetch(initial_kbdata_url).then(response => response.json())
    console.log(kbdata_json)
  } catch (err) {
    console.log('error fetch ' + initial_kbdata_url, err)
  }

  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  
  let put_result = null

  try {
    put_result = await Storage.put(projectId + '/' + constants.KBDATA_JSON, 
      JSON.stringify(kbdata_json), 
      args
    )
  } catch (err) {
    console.log('error putContent ' + constants.KBDATA_JSON, err)
  }

  console.log("createNewProject")
  console.log(put_result)

  return put_result.key.split("/")[0]
}


async function createCustomProject(kbdata) {

  const projectId = uuidv4()
  console.log("createCustomProject: " + projectId)

  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  
  try {
    await Storage.put(projectId + '/' + constants.KBDATA_JSON, 
      JSON.stringify(kbdata), 
      args
    )
  } catch (err) {
    console.log('error putContent ' + constants.KBDATA_JSON, err)
  }

  return projectId
}

async function createCustomFirmProject() {

  const projectId = uuidv4()
  console.log("createCustomFirmProject: " + projectId)

  return projectId
}

async function saveFirm(projectId, file) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  console.log('saveFirm ' + projectId)
  try {
    await Storage.put(projectId + '/' + constants.FIRMWARE_DIR + '/' + constants.FIRM_SOURCE_FILE, 
      file, 
      args
    )
  } catch (err) {
    console.log('error saveFirm ' + constants.KBDATA_JSON, err)
  }
}

async function loadProject(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  args.download = true
  try {
    const data = await Storage.get(
      projectId + '/' + constants.KBDATA_JSON, 
      args)
      .then(response => {
        return response.Body.text()
      })
    const json_data = JSON.parse(data)
    return json_data
  } catch (err) {
    console.log(err)
  }
}


async function saveProject(kbdata, svgdata, projectId) {
  console.log('saveProject ' + projectId)
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  try {
    await Storage.put(projectId + '/' + constants.KBDATA_JSON, 
      JSON.stringify(kbdata, null , "\t"), 
      args
    )
  } catch (err) {
    console.log('error putContent ' + constants.kbdata, err)
  }
  
  try {
    await Storage.put(projectId + '/' + constants.KEYBOARD_SVG, 
      svgdata, 
      args
    )
  } catch (err) {
    console.log('error putContent ' + constants.svgdata, err)
  }
}


async function getKeyboardSvgUrl(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.cacheControl = 'no-cache'
  try {
    const data = await Storage.get(projectId + '/' + constants.KEYBOARD_SVG, 
      args
    )
    return data
  } catch (err) {
    console.log('error get loadSvg', err)
  }
}


async function getHasOrdered(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.ORDER_JSON, 
      args)
    console.log('getHasOrdered')
    return true
  } catch (err) {
    return false
  }
}


async function getProjectExists(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)
  args.validateObjectExistence = true
  try {
    await Storage.get(
      projectId + "/" + constants.KBDATA_JSON, 
      args)
    console.log('getProjectExists')
    return true
  } catch (err) {
    return false
  }
}


async function removeStatus(projectId) {
  const args = Object.assign({}, constants.CUSTOM_PREFIX)

  const directories = [
    constants.BOARD_DIR,
    constants.FIRMWARE_DIR,
    constants.ENCLOSURE_DIR,
    constants.PRINT_DIR,
    constants.GCODE_DIR,
    constants.THREED_DIR
  ]

  for (let directory of directories) {
    try {
      await Storage.remove(
        projectId + "/" + directory + "/" + constants.STATUS_JSON, 
        args
      )
      console.log(directory + "/" + constants.STATUS_JSON + ' removed')
    } catch (error) {
      console.log('Error ', error);
    }
  }

}


export { createNewProject, 
  loadProject, 
  saveProject, 
  getKeyboardSvgUrl,
  createCustomProject,
  createCustomFirmProject,
  saveFirm,
  getHasOrdered,
  getProjectExists,
  removeStatus
}

