import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import { 
  Container, 
  Button, 
  Box,
  Typography,
  Stack
 } from '@mui/material'
import { styled } from '@mui/material/styles'
import { removeStatus, createCustomFirmProject, saveFirm } from '../models/ProjectManager'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DownloadIcon from '@mui/icons-material/Download'
import fileDownload from 'js-file-download'
import CircularProgress from '@mui/material/CircularProgress'


import { 
  getHasFirmStatus,
  getFirmStatus,
  //checkFirmUpdated,
  getFirm,
  generateFirmFromZip
} from '../models/FirmManager';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


function FirmGen() {

  const [projectId, setProjectId] = useState(null)
  const [firmFile, setFirmFile] = useState(null)
  const [firmStatus, setFirmStatus] = useState(null)
  const [firmFailed, setFirmFailed] = useState(false)
  const [firmWatching, setFirmWatching] = useState(false)
  const [firmGenerated, setFirmGenerated] = useState(false)

  let firmTimer = useRef()

  async function handleUpload(e) {
    console.log('handleUpload')
    const file = e.target.files[0]
    setFirmFile(file)
  }

  async function firmHandler(event) {
    if (projectId) {
      const response = await getFirm(projectId)
      fileDownload(response.Body, 'firm.uf2')
    }
  }

  function getStatusWithProgress() {
    const status = [
      "started",
      "qmk",
      "completed"
    ]
    if (0 <= status.indexOf(firmStatus)) {
      return (status.indexOf(firmStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  function intervalFirmStatus() {
    getFirmStatus(projectId).then(data => {
      console.log('getFirmStatus', data)
      setFirmStatus(data)
    })
  }
  
  async function callGenerateFirm() {
    await generateFirmFromZip(projectId)
    setFirmWatching(true)
  }

  async function initStatus() {
      // 1秒待ってみる（効果があるのか不明）
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000)
      }).then(() => {
        setFirmGenerated(false)
        setFirmStatus(null)
      })
  }


  useEffect(() => {
    console.log('firmFile changed')
    async function createProjectWithSave() {
      var _projectId = await createCustomFirmProject()
      setProjectId(_projectId)
      saveFirm(_projectId, firmFile)
      initStatus()
    }
    if (firmFile) {
      if (projectId) {
        removeStatus(projectId)
        saveFirm(projectId, firmFile)
        initStatus()
      } else {
        createProjectWithSave()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmFile])
  
  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (firmWatching === true) {
      firmTimer.current = setInterval(intervalFirmStatus, 3000)
    } else {
      clearInterval(firmTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmWatching])
  
  
  useEffect(() => {
    console.log('firmStatus', firmStatus)
    if (firmStatus === "completed" || firmStatus === "error") {
      setFirmWatching(false)
      setFirmGenerated(false)
      if (firmStatus === "completed") {
        setFirmGenerated(true)
      } else {
        setFirmFailed(true)
      }
    } else if (!firmStatus) {
      if (projectId) {
        console.log('1 projectId', projectId)
        getHasFirmStatus(projectId).then(data => {
          console.log('getHasFirmStatus', data)
          if (data === true) {
            setFirmWatching(true)
          } else {
            // status.jsonは存在しないので生成を行う。
            callGenerateFirm()
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmStatus])

  
  return (
    <Container maxWidth="md" sx={{ mt: 10 }}>
      <Stack
        direction="column"
        spacing={1}>
        <Box 
          sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 5 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            disabled={firmWatching}
          >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleUpload} />
          </Button>
        </Box>
        <Box 
          sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mb: 7 }}>
          { firmGenerated ?
              <Button onClick={firmHandler} endIcon={<DownloadIcon />}>
                Firmware
              </Button>
            :
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' }}>
              { firmWatching && !firmFailed ?
                <CircularProgressWithLabel label={getStatusWithProgress()} />
              :
                ""
              }
              { firmStatus && !firmFailed ?
                <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {firmStatus}</Typography>
              :
                ""
              }
              { firmFailed ?
                <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
              :
                ""
              }
            </Box>
          }
        </Box>
      </Stack>
    </Container>
  )
}


export default FirmGen


